.back-button-container {
  margin-top: 10px;
}

.redirecting-loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.waiting-text {
  margin-top: 30px !important;
}
