.loader-overlay-container{
    display: inline-block;
    position: relative;
}

.loader-content{
    text-align: center;
}

.loader-content {
    padding: 0px 24px 8px 24px !important;
}

.loader-title {
    padding: 8px 24px 0px 24px !important;
}

.loader-progress {
    margin-top: 10px;
}