.footer {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0em;
}

.idfy-footer-logo{
  display: flex;
  padding: 2px;
}

p > img {
  margin: 0.2em;
  height: 1.2em;
  margin-bottom: -0.2em;
}
