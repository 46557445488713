.loction-denied-container {
  display: flex;
  flex-direction: column;
}

.primary-title-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.primary-text {
  font-weight: bold !important;
}

.title-icon {
  width: 43px;
  height: 38px;
  object-fit: contain;
}

.subtitle {
  display: flex;
  opacity: 0.98 !important;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  margin: 0;
}

.numbers {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 21px !important;
}

.instruction {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.note-text {
  opacity: 0.8;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
}

.instruction-set {
  display: flex;
  flex-direction: column;
}

.error-screen-footer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-end;
}

.note-div {
  display: flex;
  flex-grow: 2;
  justify-content: center;
  align-items: flex-end;
  opacity: 0.8;
}
