.overlay-title-icon {
  height: 5em;
  object-fit: contain;
}

.simple-overlay-close-btn {
  top: 1.5% !important;
  right: 5% !important;
  position: absolute !important;
  z-index: 9999 !important;
}

.simple-overlay-logo {
  max-width: 5em;
  max-height: 5em;
  margin-bottom: 10px;
}
