.landing-page-wrapper {
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.foot-note {
  display: flex;
  justify-content: center;
  align-items: center;
}

.secured {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.redirecting-help-text {
  margin: 1rem 4rem 1rem 4rem;
}
