@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Display&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');
@font-face {
  font-family: 'PayPal';
  src: local('PayPal'),
    url(./assets/fonts/PayPalOpen-Regular.otf) format('opentype');
}
@font-face {
  font-family: 'Gilroy';
  src: local('Gilroy'), url(./assets/fonts/Gilroy-Regular.ttf);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.details > h3 {
  font-size: 1.25rem;
  padding-top: 6px;
}

.Maincontainer,
.MyAppContainer {
  display: inline-block;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  margin: auto;
}

.nameForm {
  padding: 20px;
}

.Header {
  position: relative;
}

.app-bar {
  width: 100% !important;
  height: 77px !important;
  right: initial !important;
  box-shadow: none !important;
}

.footer,
.camera-footer {
  width: 100%;
}

.thankyou-message {
  position: relative;
  top: 45%;
}

.MuiBox-root {
  height: 100% !important;
}

.page-content-holder {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1.8em 1.8em 0 1.8em;
  text-align: left;
  height: calc(100% - 11.5em);
}

.avkyc-container {
  display: flex;
  flex-direction: column;
  text-align: left;
  height: calc(100% - 11.5em);
}

/**Buttons at the bottom of page **/
.proceedButton {
  padding: 0.5em 0.5em 0 0.5em;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.idfyButton {
  border-radius: 6px !important;
  width: 76% !important;
  height: 47px !important;
}

/* Smartphones (portrait and landscape) ----------- */

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .Maincontainer,
  .MyAppContainer {
    width: 100%;
  }
}

/* Smartphones (portrait) ----------- */

@media only screen and (max-width: 320px) {
  .Maincontainer,
  .MyAppContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 351px) and (max-width: 440px) {
  .muiDrawer-move > .MuiDrawer-paperAnchorBottom {
    height: 45% !important;
  }

  .display-file-container {
    max-width: 15em !important;
  }
}

/* For smaller width devices like iphone5 */

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .display-file-container {
    max-width: 12.5em !important;
  }
}

/* iPads (portrait and landscape) ----------- */

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .Maincontainer,
  .MyAppContainer {
    width: 100%;
  }
}

/* Desktops and laptops ----------- */

@media only screen and (min-width: 1000px) and (orientation: landscape) {
  html {
    background-color: #696969;
  }

  .Maincontainer,
  .MyAppContainer,
  .footer,
  .camera-footer {
    border-radius: 0rem;
  }

  .idfyButton,
  .confirmBtn,
  .digilocker-button,
  .upload-button-wrapper {
    width: 30% !important;
  }

  .camera-holder {
    height: calc(100% - 8em) !important;
  }

  #pgCapture {
    object-fit: contain !important;
  }

  .page-content-holder {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 1.8em 5rem 0rem 5rem !important;
    text-align: left;
  }

  .MuiDrawer-paperAnchorBottom {
    width: 430px !important;
    margin: auto !important;
    position: fixed !important;
  }

  #media-container {
    height: 85% !important;
    margin: auto;
    width: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .video {
    width: 100% !important;
  }

  #media-container {
    width: 100%;
    position: relative;
    min-height: 100vh;
  }

  #pgCapture {
    object-fit: contain;
  }
}

@media only screen and (min-width: 600px) and (max-width: 1000px) {
  .footer {
    width: 100%;
  }
}

/* 
@media only screen and (min-width: 430px) and (max-width: 1000px) {
  .schedulerContainer {
    width: 430px !important;
  }
} */

@media only screen and (max-width: 370px) {
  .slotDiv {
    width: calc(50% - 5px) !important;
  }
}

/* 
@media only screen and (min-width: 430px) {
  .schedulerSnackbarDiv {
    width: 430px;
  }
} */

/* @media only screen and (max-width: 430px) {
  .schedulerSnackbarDiv {
    width: 100%;
  }
} */
