.call-dropped-content-parent {
  display: flex;
  flex-direction: column;
  height: calc(100% - 7.5em);
  justify-content: center;
  align-items: center;
}

.call-dropped-content-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
}

.call-dropped-image-bg {
  object-fit: contain;
  background-position: center;
  width: 100%;
  max-width: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  background-image: url('/public/images/blank_board.svg');
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: min(85px, 17%);
  padding-right: min(80px, 16%);
  padding-top: 20%;
  padding-bottom: 20%;
  box-sizing: border-box;
}

.call-dropped-image-bg > * {
  margin: 8px 0px !important;
  width: 100%;
}

@media only screen and (min-device-height: 750px) and (max-device-height: 1024px) {
  .call-dropped-image-bg {
    object-fit: contain;
    background-position: center;
    width: 100%;
    max-width: 300px;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url('/public/images/blank_board.svg');
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: min(85px, 17%);
    padding-right: min(80px, 16%);
    padding-top: 20%;
    padding-bottom: 20%;
    box-sizing: border-box;
  }
}
