.errhandler-root {
  height: 100%;
}
.mainGrid {
  height: 100%;
}
.imageStyle {
  height: 5em;
}
.detailsStyle {
  padding: 1.5em;
}
.default-error-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: calc(100% - 1.5em);
}

.default-error-container div:nth-child(1) {
  flex-grow: 2;
}
.default-error-container div:nth-child(2) {
  flex-grow: 2;
}
.default-error-container div:nth-child(3) {
  flex-grow: 1;
}
