.browser-not-supported-container {
  padding: 0em 1em 0em 1em;
  display: flex;
  flex-direction: column;
  height: calc(100% - 1.5em);
}

.primary-title {
  margin-top: 16%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.copy-link-snackbar-text{
  justify-content: center;
}